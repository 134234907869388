import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./about.module.css"

import ProfilePicture from "../images/face.jpg"

export default () => {
  return (
    <Layout>
      <SEO title="About me" />
      <article>
        <header>
          <h1>About me</h1>
        </header>
        <section>
          <p className={styles.avatarContainer}>
            <img
              src={ProfilePicture}
              alt="Maciej Winnicki"
              className={styles.avatar}
            />
          </p>
          <p>
            Hi! My name is Maciej Winnicki, and{" "}
            <strong>I build serverless architectures</strong>.
          </p>
          <p>
            I'm a Software Engineer and Engineering Manager with over ten years
            of experience in backend development, distributed systems, and
            cloud.
          </p>
          <p>
            I've been working with <i>serverless</i> since 2015. First, as a
            contributor and maintainer of{" "}
            <a href="https://github.com/apex/apex">Apex</a> – one of the first
            tools for managing AWS Lambda functions.
          </p>
          <p>
            Later, I joined{" "}
            <a href="https://serverless.com/">Serverless, Inc.,</a> as one of
            the early employees. As a{" "}
            <strong>Principal Software Engineer,</strong> I built{" "}
            <a href="https://github.com/serverless/event-gateway">
              Event Gateway
            </a>{" "}
            and occasionally contributed to{" "}
            <a href="https://github.com/serverless/serverless">
              Serverless Framework
            </a>
            . Event Gateway is dataflow for event-driven, serverless
            architectures. Kelsey Hightower presented it during{" "}
            <a href="https://www.youtube.com/watch?v=_1-5YFfJCqM">
              one of the keynote presentations at CloudNativeCon Europe 2018
            </a>
            .
          </p>
          <p>
            Currently, I'm a <strong>Serverless Engineer</strong> at{" "}
            <a href="https://www.stedi.com/">Stedi.</a>
          </p>
          <p>
            Also, I'm a <strong>founder</strong> of{" "}
            <a href="https://www.cloudash.dev/">
              Cloudash - Serverless monitoring. Simplified.
            </a>
          </p>

          <p>I live in Poznań, Poland. My name is pronounced 'much-yay.'</p>
          <p>
            For any questions and comments, feel free to write me an email at{" "}
            <a href="mailto:maciej@cloudash.dev">maciej@cloudash.dev.</a>
          </p>
        </section>
      </article>
    </Layout>
  )
}
